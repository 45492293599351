import React, { useState, ReactElement, useEffect } from 'react';
import useKeyDown from '@hooks/UseKeyDown';
import Popup from '@components/Popup';
import Section from '@layouts/Section';
import MemberCard from '@components/MemberCard';
import NormanBarker from '@assets/images/about/norman-barker.png';
import JulieBryce from '@assets/images/about/julie-bryce.png';
import SethShelnutt from '@assets/images/about/seth-shelnutt.png';
import IsaiahNorton from '@assets/images/about/isaiah-norton.png';
import DirkEddelbuettel from '@assets/images/about/dirk-eddelbuettel.png';
import AndreasNtalakas from '@assets/images/about/andreas-ntalakas.png';
import AlexDiktampanidis from '@assets/images/about/alex-diktampanidis.png';
import AaronWolen from '@assets/images/about/aaron-wolen.jpg';
import KostasSarantopoulos from '@assets/images/about/kostas-sarantopoulos.jpg';
import KostasTsitsimpikos from '@assets/images/about/kostas-tsitsimpikos.jpg';
import VivianNguyen from '@assets/images/about/vivian-nguyen.jpg';
import StavrosPapadopoulos from '@assets/images/about/stavros-papadopoulos.jpg';
import RebekahDavis from '@assets/images/about/rebekah-davis.jpg';
import YpatiaTsavliri from '@assets/images/about/ypatia-tsavliri.jpg';
import JuliaDark from '@assets/images/about/julia-dark.jpg';
import DavidKim from '@assets/images/about/david-kim.jpg';
import StefanNaglee from '@assets/images/about/stefan-naglee.jpg';
import AggelikiPapailiou from '@assets/images/about/papailiou-aggeliki.jpg';
import VasilisHeliotis from '@assets/images/about/vasilis-heliotis.png';
import DimitrisStaratzis from '@assets/images/about/dimitris-staratzis.jpg';
import LucRancourt from '@assets/images/about/luc-rancourt.jpg';
import PaulRavkin from '@assets/images/about/paul-ravkin.jpg';
import PaulFisher from '@assets/images/about/paul-fisher.jpg';
import GeorgePowley from '@assets/images/about/george-powley.jpg';
import JohnKerl from '@assets/images/about/john-kerl.jpg';
import ChadKrilow from '@assets/images/about/chad-krilow.png';
import MargrietGroenendijk from '@assets/images/about/margriet-groenendijk.jpg';
import JaredGentry from '@assets/images/about/jared-gentry.png';
import RobertBindar from '@assets/images/about/robert-bindar.png';
import NickKules from '@assets/images/about/nick-kules.jpg';
import MaherHamdi from '@assets/images/about/maher-hamdi.png';
import ShaunReed from '@assets/images/about/shaun-reed.png';
import JeremyLeipzig from '@assets/images/about/jeremy-leipzig.jpg';
import YeonhoKim from '@assets/images/about/yeonho-kim.jpg';
import NikosPapailiou from '@assets/images/about/nikos-papailiou.png';
import TheodoreTsirpanis from '@assets/images/about/theodore-tsirpanis.png';
import PaulDavis from '@assets/images/about/paul-davis.png';
import VlasisPitsios from '@assets/images/about/vlasis-pitsios.png';
import XanthosXanthopoulos from '@assets/images/about/xanthos-xanthopoulos.png';
import MarkRubin from '@assets/images/about/mark-rubin.png';
import PaulHoffman from '@assets/images/about/paul-hoffman.png';
import JosephAlvertis from '@assets/images/about/joseph-alvertis.jpeg';
import StathisMaroulis from '@assets/images/about/stathis-maroulis.jpeg';
import OrestisSerkelidis from '@assets/images/about/orestis-serkelidis.jpg';
import YannisAsimakopoulos from '@assets/images/about/yannis-asimakopoulos.jpg';
import SeanGillies from '@assets/images/about/sean-gillies.jpg';
import TomHannon from '@assets/images/about/tom-hannon.png';
import ParisMorgan from '@assets/images/about/paris-morgan.png';
import DaveTry from '@assets/images/about/dave-try.jpg';
import KeithMcClellan from '@assets/images/about/keith-mcClellan.jpg';
import NickVigilante from '@assets/images/about/nick-vigilante.jpg';
import DusanBaran from '@assets/images/about/dusan-baran.jpg';
import KyleOShea from '@assets/images/about/kyle-o-shea.jpg';
import BernadetteSinigalia from '@assets/images/about/bernadette-sinigalia.jpg';
import AgisKounelis from '@assets/images/about/agis-kounelis.jpg';
import PhilippWinter from '@assets/images/about/philipp-winter.jpg';
import EricGoldstein from '@assets/images/about/eric-goldstein.jpg';
import ChaseChristensen from '@assets/images/about/chase-christensen.png';
import AneesaValentine from '@assets/images/about/aneesa-valentine.png';
import JohnACarter from '@assets/images/about/john-a.-carter.png';
import BarryConnolly from '@assets/images/about/barry-connolly.png';
import RyanRoelke from '@assets/images/about/ryan-roelke.png';
import RyanWilliams from '@assets/images/about/ryan-williams.png';
import SeanMcGillen from '@assets/images/about/sean-mcgillen.jpg';
import SpencerSeale from '@assets/images/about/spencer-seale.png';
import DevikaGarg from '@assets/images/about/devika-garg.jpg';
import DimitrisSideris from '@assets/images/about/dimitris-sideris.jpg';
import BruceMartin from '@assets/images/about/bruce-martin.jpg';
import JeremyBalian from '@assets/images/about/jeremy-balian.jpg';
import GeorgeVlastarakis from '@assets/images/about/george-vlastarakis.jpg';
import JohnMoutafis from '@assets/images/about/john-moutafis.jpg';
import BharatManmode from '@assets/images/about/bharat-manmode.jpg';
import PaoloNarvaez from '@assets/images/about/paolo-narvaez.jpg';
import MollyClancy from '@assets/images/about/molly-clancy.jpg';
import ChristinaPucci from '@assets/images/about/christina-pucci.jpg';
import JamieSherkness from '@assets/images/about/jamie-sherkness.jpg';
import DanielGoya from '@assets/images/about/daniel-goya.jpg';
import ThomasSouliotis from '@assets/images/about/thomas-souliotis.png';
import AngelaBrown from '@assets/images/about/angela-brown.jpg';
import MikaelAustin from '@assets/images/about/mikael-austin.png';

interface Member {
  name: string;
  image: any;
  link: string;
  title: string;
  bio: React.ReactNode;
}

const members: Member[] = [
  {
    name: 'Stavros Papadopoulos',
    title: 'Founder & CEO',
    link: 'https://www.linkedin.com/in/stavrospap/',
    bio: 'Prior to founding TileDB, Inc. in February 2017, Dr. Stavros Papadopoulos was a Senior Research Scientist at the Intel Parallel Computing Lab, and a member of the Intel Science and Technology Center for Big Data at MIT CSAIL for three years. He also spent about two years as a Visiting Assistant Professor at the Department of Computer Science and Engineering of the Hong Kong University of Science and Technology (HKUST). Stavros received his PhD degree in Computer Science at HKUST under the supervision of Prof. Dimitris Papadias, and held a postdoc fellow position at the Chinese University of Hong Kong with Prof. Yufei Tao.',
    image: StavrosPapadopoulos,
  },
  {
    name: 'Seth Shelnutt',
    title: 'CTO',
    link: 'https://www.linkedin.com/in/seth-shelnutt/',
    bio: 'Seth Shelnutt received his B.S. in Statistics from the University of Florida. His interests primarily revolve around building systems for massive data handling and manipulation. Seth is a regular contributor to open source software and has worked on developing a new custom storage engine for MariaDB/MySQL. Prior to joining TileDB, Inc., Seth worked as a software engineer at Sprint, a Tier 1 telecommunications company.',
    image: SethShelnutt,
  },
  {
    name: 'Julie Bryce',
    title: 'Chief Marketing Officer',
    link: 'https://www.linkedin.com/in/julie-bryce/',
    bio: 'Julie Bryce leads Go to Market at TileDB. She brings 20 years of experience from roles at Red Hat and Oracle, as well as venture-backed startups. Specializing in complex B2B technology, she launched a fractional Chief Marketing Officer practice in 2018, collaborating closely with 20+ firms, many P/E or VC-backed and founder-led. Before TileDB, Julie led go to market and strategy for an AI/NLP startup, guiding it through the disruptive market entry of generative AI, culminating in a successful Series A funding round. She lives in North Carolina with her partner and two kids. She loves road trips, strong coffee and NW Montana.',
    image: JulieBryce,
  },
  {
    name: 'Norman Barker',
    title: 'VP of Geospatial',
    link: 'https://www.linkedin.com/in/normanbarker/',
    bio: "Norman is the VP of Geospatial at TileDB. Prior to joining TileDB, Norman focused on spatial indexing and image processing, and held engineering positions at Cloudant, IBM and Mapbox. He has a master's degree in Mathematics from the University of Durham, England. In his free time, Norman likes to garden and fix up his old house.",
    image: NormanBarker,
  },
  {
    name: 'Dirk Eddelbuettel',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/dirkeddelbuettel/',
    bio: 'Dirk Eddelbuettel has over twenty years of data science and quantitative research experience primarily from the financial sector. He is the author / coauthor of several dozen R packages on CRAN including Rcpp; co-creator of the Rocker Project for R use on Docker; creator / maintainer of three R SIG mailing list; editor of two CRAN Task Views; an editor at the Journal of Statistical Software; the Debian/Ubuntu maintainer for R, numerous CRAN packages and other quantitative software; an elected board member of the R Foundation; a founding member of the R/Finance conference series; has served on the program committee for several useR! conferences; given invited keynotes, talks or workshops about R, Finance and High Performance Computing in the US, Canada, Europe, and Japan; has published papers in JSS, CSDA and other journals as well as a book on Rcpp in Springer useR! series; an adjunct Clinical Professor at the University of Illinois at Urbana-Champaign teaching a class on Data Science Programming Methods; and holds a MA and PhD in Mathematical Economics from EHESS in France, and a MSc in Industrial Engineering from KIT in Germany.',
    image: DirkEddelbuettel,
  },
  {
    name: 'Isaiah Norton',
    title: 'VP of Engineering',
    link: 'https://www.linkedin.com/in/isaiahnorton/',
    bio: "Isaiah Norton is a Principal Software Engineer at TileDB. Prior to joining TileDB, he worked at Brigham and Women's Hospital as a research software engineer in medical imaging and surgical navigation. He received a B.S. degree in Mathematics from Boston University. He has contributed to a number of open source projects including 3D Slicer and the Julia Language.",
    image: IsaiahNorton,
  },
  {
    name: 'Andreas Ntalakas',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/andreas-ntalakas-0b30839/',
    bio: "Andreas has a master's degree in Electrical Engineering from Aristotle University of Thessaloniki, Greece. Before joining TileDB he worked as a software engineer at PCCW Global, a Tier 1 telecommunications company. He has also worked for the European Commission, and companies developing ERP systems and embedded software. He has experience working with stream processing pipelines and image processing.",
    image: AndreasNtalakas,
  },
  {
    name: 'Alex Diktampanidis',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/alex-diktampanidis-07b068120/',
    bio: 'Alex is a frontend developer focusing on building custom web and mobile applications using JavaScript. His speciality is pixel-perfect responsive UIs, while keeping the code reusable, testable, and readable. Prior to joining TileDB, Alex worked at høly, where he was responsible for leading the tech team and building static websites, mobile applications and large scale web applications. In his free time, he likes to cook and jam with his synths.',
    image: AlexDiktampanidis,
  },
  {
    name: 'Aaron Wolen',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/aaronwolen/',
    bio: "Aaron's background is in computational biology and genomics. After earning a PhD in Human and Molecular Genetics, he received post-doctoral training at the Virginia Institute for Psychiatric and Behavioral Genetics, and eventually joined the faculty of Virginia Commonwealth University (VCU), where he co-founded VCU's Data Science Lab. Before joining TileDB, he served as Director of Bioinformaics for the University of Tennessee Health Science Center's Transplant Research Institute. He is also an active open source software contributor and has authored or co-authored R packages on CRAN, Bioconductor, and rOpenSci.",
    image: AaronWolen,
  },
  {
    name: 'Kostas Sarantopoulos',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/ksarantopoulos/',
    bio: 'Kostas is a frontend developer with a keen eye for design and a passion about well-built and well-documented software. Prior to joining TileDB, Kostas was a frontend developer at e-table, an online restaurant-reservation website, where he was responsible for the maintenance and development of new features for the reservation platform. He then joined cubeca, a marketplace for home financing as a lead frontend developer, where he supervised the whole design process and was responsible for building the web application.',
    image: KostasSarantopoulos,
  },
  {
    name: 'Kostas Tsitsimpikos',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/konstantinos-tsitsimpikos/',
    bio: 'Kostas holds a M.Sc degree in Computer Science from Ecole Polytechnique Federale de Lausanne (EPFL).His interests lie on the areas of data storage, data intensive systems and machine learning. Prior joining TileDB, he has been working as a Machine Learning Engineer at BEAT. He has also worked as an intern Software Engineer at CERN.',
    image: KostasTsitsimpikos,
  },
  {
    name: 'Vivian Nguyen',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/vivian-nguyen-199aa258/',
    bio: "Vivian holds a B.S. degree in Computer Science and B.S. degree in Biochemistry from the University of Texas at Austin. Prior to joining TileDB, Vivian worked at UT's Applied Research Laboratories, developing and maintaining software to support researchers in ocean acoustics and signal processing. In her free time, she likes to cook and collect vinyl.",
    image: VivianNguyen,
  },
  {
    name: 'Rebekah Davis',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/bekadavis9/',
    bio: 'Rebekah Davis received her B.S. in Computer Engineering from Northeastern University. Prior to joining the TileDB team, she worked in the Aerospace field as a Computer Engineer at Draper, Pratt & Whitney and MatrixSpace. She enjoys solving complex problems and in her spare time collects and refurbishes antique cameras.',
    image: RebekahDavis,
  },
  {
    name: 'Ypatia Tsavliri',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/ypatia/',
    bio: 'Ypatia holds a M.Sc. degree in Computer Science from Ecole Polytechnique Federale de Lausanne (EPFL) and a M.Eng. degree in Electrical and Computer Engineering from National Technical University of Athens (NTUA). Her primary interests include complex systems engineering, networks and security. Prior to joining TileDB, Ypatia has mainly worked as a software engineer for Cisco Systems, focusing on building and testing large-scale wireless networking equipment. In her free time she is a regular amateur theater group member.',
    image: YpatiaTsavliri,
  },
  {
    name: 'Julia Dark',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/julia-dark/',
    bio: 'Dr. Julia Dark is Principal Software Engineer at TileDB working on geospatial applications. Prior to joining TileDB, Julia held a position as a Research Scientist and Group Lead of the CodeWorks at CFD Research Corporation. Julia has a PhD in Applied Mathematics from the University of California, Merced and a BS in Mathematics from UCLA.',
    image: JuliaDark,
  },
  {
    name: 'David Kim',
    title: 'Senior Sales Development Representative',
    link: 'https://www.linkedin.com/in/davidhkim98/',
    bio: "David is a Senior Sales Development Representative at TileDB. Prior to joining TileDB, David was a part of the business development team at Collibra ($2B valuation; $100M revenue) and helped them massively scale the Public Sector team, bringing in new logos like the United Nations, State of California, and Federal Reserve Bank of New York. David graduated from Binghamton University's School of Management (cum laude) and was born and raised in New York City. In his free time he enjoys film photography, snowboarding, and hanging out with his cats.",
    image: DavidKim,
  },
  {
    name: 'Stefan Naglee',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/stefan-naglee-a6948882/',
    bio: 'Stefan Naglee received his B.S. in Information Systems from the University of Florida. Prior to joining TileDB, Stefan worked as an Engineering Manager at HelloFresh in Berlin, while being an active contributor to their internal services and infrastructure. His previous stops also included Sprint and InfoTech in Florida. Stefan likes to spend his free cooking, gaming, and traveling.',
    image: StefanNaglee,
  },
  {
    name: 'Aggeliki Papailiou',
    title: 'Creative Design Lead',
    link: 'https://www.linkedin.com/in/aggeliki-papailiou-45212860/',
    bio: 'Aggeliki is Lead Graphic Designer at TileDB. She holds a Bachelor’s degree at Computer Science and a Master of Arts at Graphic Design & Multimedia. She is also Certified Associate in Project Management (PMI - CAPM), Certified Lean Six Sigma Excellence Yellow Belt holder (IASSC - ICYB) and Certified Systemic Analyst Professional (CSAP). Prior to joining TileDB, she worked as a Graphic Design Specialist, leading visual identity development and marketing projects at the Institute of Industrial and Business Training of the Hellenic Federation of Enterprises (SEV). Aggeliki has high analytical skills, desire for continuous improvement, dedication and positive attitude. Self-motivated to learn and constantly raise the standards, with passion for excellence and attention to pixel perfect detail.',
    image: AggelikiPapailiou,
  },
  {
    name: 'Vasilis Heliotis',
    title: 'Senior Product Designer',
    link: 'https://www.linkedin.com/in/vasilis-heliotis/',
    bio: 'Vasilis is a Senior Product Designer at TileDB. His interest lies in designing compelling digital products and experiences. He works as an integrated part of the product development cycle and his primary focus is delivering results that promote visual excellence, usability and delight. Before joining TileDB, he worked as a UI / UX and Product Designer in a handful of projects across multiple industries, from consumer to business products in fintech, energy, banking and tourism. He also enjoys gaming, coffee, music and exploring new software.',
    image: VasilisHeliotis,
  },
  {
    name: 'Dimitris Staratzis',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/dimitris-staratzis-4b388b170/',
    bio: 'Dimitris completed his B.Sc. in Computer Science from Athens University of Economics and Business in Greece and holds a M.Sc. from Boston University. His research interests lie in Database Systems optimization. Prior to joining TileDB, Inc, Dimitris worked as Events Manager at LEGO Greece. Later, he worked as a software engineer in a startup company developing a music-related Android application. In his free time, he enjoys playing tennis and traveling!',
    image: DimitrisStaratzis,
  },
  {
    name: 'Luc Rancourt',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/luc-rancourt-7964351b8/',
    bio: 'Luc Rancourt received his B.S. in Computer Engineering from the University of Sherbrooke. Prior to joining TileDB, Luc worked at Microsoft for the Xbox division where he contributed to many projects from Kinect prototyping, storage drivers, to graphics apis and everything in between. In his free time, he enjoys kitesurfing around the world, rock climbing, and trekking in remote places.',
    image: LucRancourt,
  },
  {
    name: 'Paul Ravkin',
    title: 'Head of Operations',
    link: 'https://www.linkedin.com/in/paul-ravkin-15115927/',
    bio: 'Paul is the Head of Operations at TileDB, working throughout all areas of the company unifying individuals and departments behind one collective vision. Prior to joining TileDB, Paul worked for Kruze Consulting performing accounting & finance services for 20+ startups ranging from pre-revenue to $20 million in ARR. Previous stop includes Financial Controller at blooom.com which was the fastest growing FinTech company in the USA at the time increasing its AUM from $1 billion to $3 billion in under 2 years. Paul spends his free time camping, fishing, golfing and traveling with his wife and two little boys. Paul speaks fluent Croatian and enough German to order a beer and get into trouble with the locals about who’s soccer team will win the world cup.',
    image: PaulRavkin,
  },
  {
    name: 'Paul Fisher',
    title: 'Senior Software Engineer',
    link: '',
    bio: 'Paul Fisher is a Senior Software Engineer at TileDB. He earned a B.S. in Software Engineering at the Rochester Institute of Technology in beautiful upstate New York. Before working at TileDB, he was a software engineer at YouTube, helping ensure that users could enjoy their cat videos as smoothly and efficiently as possible. He is a competitive rower and cannot wait for live music to come back.',
    image: PaulFisher,
  },
  {
    name: 'George Powley',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/georgepowley/',
    bio: 'George Powley has over twenty years experience in computer architecture, hardware, and software design. Prior to joining TileDB, George was a Principal Engineer at the Intel Corporation where he focused on Server CPU development, HPC software optimization, and hardware acceleration. He was the primary author of the open-source Genomics Kernel Library, which accelerates performance of the Genomic Analysis Toolkit (GATK). George received his PhD, MS, and BS in Electrical Engineering from the Ohio State University.',
    image: GeorgePowley,
  },
  {
    name: 'John Kerl',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/johnkerl/',
    bio: (
      <>
        John Kerl brings to TileDB extensive experience in systems, data pipelines, and observability. In his spare time, he enjoys hiking,
        vegan cooking, and amateur photography, and is the maintainer of the{' '}
        <a className="hover-link" href="https://github.com/johnkerl/miller" target="_blank" rel="noreferrer noopener">
          Miller
        </a>{' '}
        open-source data-processing tool. He holds a PhD in mathematics from the University of Arizona.
      </>
    ),
    image: JohnKerl,
  },
  {
    name: 'Chad Krilow',
    title: 'Genomics Solutions Architect',
    link: 'https://www.linkedin.com/in/chad-nicholas-k-97557a26/',
    bio: "Chad Krilow is a Genomics Solutions Architect at TileDB. He earned a B.S. in Computer Science at The University of Saskatchewan in Saskatoon, Saskatchewan, Canada. Before joining TileDB, he was a scientific database manager at the NIH, working on the development of bioinformatics software for the analysis of complex disease research. He enjoys spending time with his wife and two young children, where they live in the Washington D.C area. In his spare time he enjoys traveling, scuba diving, playing hockey, golfing, and considers himself a 'foodie'.",
    image: ChadKrilow,
  },
  {
    name: 'Margriet Groenendijk',
    title: 'Geospatial Data Scientist',
    link: 'https://www.linkedin.com/in/margrietgroenendijk/',
    bio: 'Margriet is a Geospatial Data Scientist at TileDB based in the UK. She joined from IBM, where she worked as a Data & AI Developer Advocate. Margriet has a background in environmental sciences and climate research. She worked with large observational datasets and global climate models during her PhD research in Amsterdam and as a Research Fellow in the UK.',
    image: MargrietGroenendijk,
  },
  {
    name: 'Jared Gentry',
    title: 'Senior Sales Development Representative',
    link: 'https://www.linkedin.com/in/jaredgentry/',
    bio: 'Jared is a Senior Sales Development Representative at TileDB. Prior to joining TileDB, Jared was a part of the business development team at Collibra within the Commercial Sector. Jared is a Clemson University Marketing Graduate, and was born and raised in Greenville, South Carolina. In his free time, Jared enjoys watching sports, and anything Tech and Outdoors.',
    image: JaredGentry,
  },
  {
    name: 'Robert Bindar',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/robertbindar/',
    bio: "Robert received his BEng from University Politehnica of Bucharest. Prior to joining TileDB, he worked 3 years for MariaDB Foundation as a Server developer focusing on handling community PRs, feature implementation and bug fixes. Robert is now based mostly in Brasov, Romania, an old beautiful mountainside city, feel free to drop him an email whenever you need help or a local's advice about travelling to Romania.",
    image: RobertBindar,
  },
  {
    name: 'Nick Kules',
    title: 'Geospatial Solution Architect',
    link: 'https://www.linkedin.com/in/nick-kules-0a98a656/',
    bio: 'Nick is a Geospatial Solution Architect at TileDB. Prior to Joining TileDB, Nick worked with Dewberry and NV5 geospatial (formally Quantum Spatial Inc) focused on geospatial technologies and solutions. These included managing data acquisition and processing, as well as developing processing applications and storage solutions for large data volumes. He holds a M.S in Natural Resources and a B.S. in Earth Science from Oregon State University. Nick is an active industry contributor and is involved with the American Society for Photogrammetry and Remote Sensing (ASPRS). He currently is a Director for the Florida ASPRS region board of directors, and an active member of the ASPRS LAS format working group, and ASPRS positional accuracy working group.',
    image: NickKules,
  },
  {
    name: 'Maher Hamdi',
    title: 'Marketing Associate',
    link: 'https://www.linkedin.com/in/maherhamdi98/',
    bio: 'Graduated from George Washington University with a Master in International Business. Fascinated by the field of big data and marketing. Big team player.',
    image: MaherHamdi,
  },
  {
    name: 'Shaun Reed',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/shaunrd0/',
    bio: 'Shaun Reed graduated in 2022 with a B.S. in Computer Science from Kent State University. Before joining TileDB, he worked as a Systems Operations Analyst for Integratouch to monitor global service infrastructure for SiriusXM. He has a deep interest in writing efficient and maintainable code, and in his free time enjoys camping and traveling.',
    image: ShaunReed,
  },
  {
    name: 'Jeremy Leipzig',
    title: 'Senior Product Manager',
    link: 'https://www.linkedin.com/in/leipzig/',
    bio: "Jeremy Leipzig has over 20 years of experience as a bioinformatics software developer and analyst in academia and industry, as well as diagnostic, therapeutic and platform startups. He has co-authored over 40 peer-reviewed publications and an O'Reilly book. Jeremy's university training was in biology and computer science with an emphasis on bioinformatics and statistical genetics, and a Ph.D. in information science focused on scientific workflows, metadata and reproducible research. He enjoys an outdoor lifestyle with his family in Whitefish, Montana.",
    image: JeremyLeipzig,
  },
  {
    name: 'Yeonho Kim',
    title: 'Demand Generation Marketing Manager',
    link: 'https://www.linkedin.com/in/yeonhokim/',
    bio: 'Yeonho (YK) is a Demand Generation Marketing Manager at TileDB. Before joining TileDB, he built and owned inbound pipelines to land and expand a Korean Series A SaaS startup in the USA that resulted in customers from digital therapeutics, media entertainment, and education. YK spends his free time enjoying the process of being bad at fishing and golfing.',
    image: YeonhoKim,
  },
  {
    name: 'Nikos Papailiou',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/nikos-papailiou-b1812b86',
    bio: 'Nikos Papailiou received his PhD in Distributed Databases from National Technical University of Athens. Prior to joining TileDB, Nikos worked as a Senior Software engineer at Google. He is interested in data management, distributed systems and cloud computing. He has experience with several distributed processing frameworks and has worked in developing novel data management systems and algorithms.',
    image: NikosPapailiou,
  },
  {
    name: 'Theodore Tsirpanis',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/teo-tsirpanis/',
    bio: "Theodore got his bachelor's degree in Applied Informatics from the University of Macedonia in Thessaloniki, Greece. His main area of interest revolves around writing high-performance developer tools for the .NET ecosystem, and apart from maintaining his own, has contributed extensively to other open source .NET projects, including .NET itself. Other than programming, his hobbies are taking long walks around the city, watching movies and browsing Wikipedia.",
    image: TheodoreTsirpanis,
  },
  {
    name: 'Paul Davis',
    title: 'Principal Software Engineer',
    link: '',
    bio: 'Paul is a Principal Software Engineer at TileDB. Prior to TileDB he spent time working at Airbnb, IBM, Cloudant (YC S08), New England BioLabs, and The University of Iowa. Paul has worked on a wide range of software from image processing and bioinformatics to distributed databases and compiler bugs. Beyond software, Paul is your standard issue midwesterner who enjoys a cold beer while cooking over a hot fire.',
    image: PaulDavis,
  },
  {
    name: 'Vlasis Pitsios',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/vlasis-pitsios-618b7963',
    bio: "Vlasis is a software engineer at TileDB and has a master's degree in Computer Engineering from University of Patras, Greece. Prior to joining TileDB, he worked as a software engineer in many large scale software products across multiple industries. He has experience building scalable and resilient applications for telecommunications companies, e-commerce platforms and European institutions. He is interested in software engineering and distributed systems. In his free time, he enjoys spending time with his family and hiking.",
    image: VlasisPitsios,
  },
  {
    name: 'Xanthos Xanthopoulos',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/xanthosxanthopoulos',
    bio: 'I received a B.Sc in Computer Science from Athens University of Economics and Bussiness and I am expected to graduate from the M.Sc in Computer Science this December (2022). I have worked as Data analyst and Software Engineer for the  Hellenic Competition Commission.',
    image: XanthosXanthopoulos,
  },
  {
    name: 'Mark Rubin',
    title: 'VP of Sales',
    link: 'https://www.linkedin.com/in/markrrubin/',
    bio: (
      <>
        Throughout my career I have been on the cutting edge of disruptive technology that helps builders transform the business of their
        customers. By doing so they have enhanced the value of their business by increasing customer satisfaction, shareholder value and
        employee success. This is only possible when you have a maniacal focus on customer success.
        <br />
        Seasoned sales executive with leadership presence covering sales (direct and channels), marketing, and business development in
        start-ups and high growth software companies. Helped build two successful start-ups to over $100M in sales and $1B in valuation,
        MySQL and Powersoft. I have built teams, exceeded quotas, and created value for customers, employees, and investors. In addition to
        my record of success, I possess the key intangible qualities, including high energy , motivation and empathy, which has helped me
        create winning teams.
        <br />
        Specialties: Cloud, Open Source, Direct, Channels, Business Development, Sales and Inside Sales, Hadoop, NoSQL, Database, Analytics,
        SaaS, Security, Infrastructure, Natural Language Processing, AI, Machine Learning, and Deep Learning.
      </>
    ),
    image: MarkRubin,
  },
  {
    name: 'Paul Hoffman',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/pauljhoffman/',
    bio: 'Paul is a software engineer with a background in population and single-cell genomics. Prior to joining TileDB, Paul worked as a bioinformatician with the Satija and Lappalainen labs at the New York Genome Center, where he helped build tools for analyzing long-read sequencing data and served as the maintainer and lead developer of the R package Seurat. He earned his bachelor’s degree in Microbiology from the University of Minnesota – Twin Cities.',
    image: PaulHoffman,
  },
  {
    name: 'Joseph Alvertis',
    title: 'VP of Product',
    link: 'linkedin.com/in/alvertisjo',
    bio: 'Iosif (Joseph) is the Vice President of Product at TileDB. Prior Iosif has worked as a Product Leader and Product Manager for fast-growing startups with an emphasis on B2B products. Iosif holds a Ph.D. and Diploma of Engineering in Electrical and Computer Engineering from National Technical University of Athens (NTUA), and an MBA from Athens University of Economics and Business (AUEB).',
    image: JosephAlvertis,
  },
  {
    name: 'Stathis Maroulis',
    title: 'Software Engineer / SRE',
    link: 'https://www.linkedin.com/in/stathis-maroulis/',
    bio: 'Prior to 2019, I immersed myself in academia, focusing on my PhD. However, I made a significant decision to transition into the industry and pursue my true passion. Since then, I have been working as a dedicated Site Reliability Engineer. My expertise lies in designing and implementing scalable and reliable systems, as well as my affinity for automation and observability. I find immense satisfaction in crafting solutions that prioritize scalability, reliability, and efficiency.',
    image: StathisMaroulis,
  },
  {
    name: 'Orestis Serkelidis',
    title: 'Graphic Designer',
    link: 'https://www.linkedin.com/in/orestis-serkelidis-3975311b3/',
    bio: 'Orestis is a Graphic Designer at TileDB. Self-taught professional with over a decade of experience in the design industry. With a strong background in both print and digital media, he is passionate about continually expanding his knowledge and skills. He enjoys experimenting with innovative design approaches and excels at solving complex visual challenges.',
    image: OrestisSerkelidis,
  },
  {
    name: 'Yannis Asimakopoulos',
    title: 'Director of Demand Generation',
    link: 'https://www.linkedin.com/in/yannis-asimakopoulos/',
    bio: "Yannis leads demand generation at TileDB. A full-stack, SaaS marketer, he brings experience in creating demand for global, mid-market & enterprise SaaS products. Prior to joining TileDB, he led demand generation at various funded startups in different industries spanning maritime, supply chain, dev tools & finance. He holds an MSc in Business Administration from Rotterdam School of Management and currently resides in Athens, Greece. When he's not weaving marketing magic, you'll find him basking in the glory of good jokes, relishing hearty cuisine, or out in the wild, soaking up the great outdoors.",
    image: YannisAsimakopoulos,
  },
  {
    name: 'Sean Gillies',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/sean-gillies/',
    bio: 'Two decades of experience making software and technical standards for producing and distributing geographic information on the internet. Author of Shapely and Rasterio and a member of the GDAL Project Steering Committee. Away from a computer, most likely to be found in the kitchen or on a mountain trail.',
    image: SeanGillies,
  },
  {
    name: 'Tom Hannon',
    title: 'Director of Finance',
    link: 'https://www.linkedin.com/in/tom-hannon-a92b6910/',
    bio: 'Tom leads the finance organization at TileDB. With both public and private company experience, he brings a strong go-to-market mindset, in addition to a traditional finance and accounting best in practice approach. Being able to design, build and lead the full finance and accounting suite, Tom joined TileDB from prior companies that range in revenue from the first dollar to $2bn+. Bringing a culture that won repeated awards annually in NYC, Tom is ready to scale TileDB on every level. Tom lives in New York City and spends his free time travelling and exploring with his family.',
    image: TomHannon,
  },
  {
    name: 'Paris Morgan',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/jparismorgan/',
    bio: 'Paris Morgan received his B.S. in Computational Biology from Duke University, where he did plant biology and genomics research at the Dong Lab, NASA, and iMicrobes. Prior to joining TileDB, he worked on Augmented Reality at Microsoft, 8th Wall, and Niantic. In his free time, he reads, plays soccer, and cooks.',
    image: ParisMorgan,
  },
  {
    name: 'Dave Try',
    title: 'Senior Software Engineer',
    link: 'https://linkedin.com/in/davetry',
    bio: "Dave started his career as a Unix and Middleware administrator at IBM. Since then, he's managed data centers, worked in consulting before diving into devops and cloud infrastructure. Before joining TileDB, he worked for Sourcegraph where he focused on building developer platforms and tooling. He's also worked for Puppet, focusing on automating cloud and container technologies. In his free time, he enjoys cycling, coffee and music.",
    image: DaveTry,
  },
  {
    name: 'Keith McClellan',
    title: 'Senior Director of Sales Engineering',
    link: 'https://www.linkedin.com/in/keithmcclellan/',
    bio: "Keith McClellan leads Sales Engineering at TileDB. Prior to his time here, he's worked at several data-focused high-growth startups in a variety of technical roles. He holds a B.S. in Information Science and Technology from Penn State and is based in the Washington, DC area.",
    image: KeithMcClellan,
  },
  {
    name: 'Nick Vigilante',
    title: 'Senior Technical Writer',
    link: 'https://www.linkedin.com/in/n-i-c-k-v/',
    bio: 'Nick is a Senior Technical Writer at TileDB. He has a B.S. from Rensselaer Polytechnic Institute in mathematics with a concentration of operations research. He has worn many hats in his career, including technical support engineer, knowledge base architect, SQL developer, technical writer, and documentation infrastructure architect. Outside of work, Nick enjoys tinkering with electronics and home automation, experimenting in the kitchen, and playing with his cat, Toby.',
    image: NickVigilante,
  },
  {
    name: 'Dušan Baran',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/du%C5%A1an-baran-908153109/',
    bio: "Dušan Baran holds his Master's degree in Computer Science from Masaryk University in Brno. Prior to joining TileDB, Dušan worked as a software engineer working on autonomous yard trucks. He is really passionate about writing efficient and safe C++ code. In his free time he likes to travel, mostly to Egypt in order to scuba dive.",
    image: DusanBaran,
  },
  {
    name: 'Kyle O Shea',
    title: 'Senior Product Manager',
    link: 'https://www.linkedin.com/in/kyle-o-shea/',
    bio: "Kyle received his M.Sc. in Economics from University College Cork. He started his career in financial analytics, before co-founding Kyso - the universal aggregator for data-based insights & collaboration, where he headed up the team's Product strategy & Enterprise Sales efforts. He continues to help manage Kyso as an open source project. Big reader, speaks German & Spanish (at least enough to get by!), and loves to play padel & football in his spare time.",
    image: KyleOShea,
  },
  {
    name: 'Bernadette Sinigalia',
    title: 'Product Designer',
    link: 'https://www.linkedin.com/in/bernadette-sinigalia-b8b5a052/',
    bio: 'Bernadette is a product designer at TileDB. She is passionate about creating great user experiences and always supports the value of UX within a product. Before joining TileDB, she worked as a UX/ UI Designer for a number of B2B and E-commerce web apps across fintech, legaltech and other sectors. She enjoys good coffee, making mixtapes and going on excursions in the mountains.',
    image: BernadetteSinigalia,
  },
  {
    name: 'Agisilaos Kounelis',
    title: 'Software Engineer',
    link: 'https://www.linkedin.com/in/kounelisagis/',
    bio: "Agisilaos is a software engineer at TileDB, holding a master's degree in Computer Engineering & Informatics from the University of Patras, Greece. Before joining TileDB, he interned at CERN, Microsoft, Amazon and EPFL and has also participated in Google Summer of Code with Mozilla and KDE. He has participated in various coding competitions and some of the world's top hackathons, complemented by university research experience.",
    image: AgisKounelis,
  },
  {
    name: 'Philipp Winter',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/phwinter/',
    bio: 'Philipp enjoys designing scalable, robust, and performant systems. Prior to joining TileDB, he worked in both engineering and research roles at Brave Software, The Tor Project, UC San Diego, and Princeton University. Philipp earned a Ph.D. in Computer Science from Karlstad University in Sweden, where he learned to appreciate Swedish cinnamon buns.',
    image: PhilippWinter,
  },
  {
    name: 'Eric Goldstein',
    title: 'Chief Revenue Officer',
    link: 'https://www.linkedin.com/in/eric-goldstein-4341211/',
    bio: 'Eric Goldstein joins the TileDB team as the Chief Revenue Officer, leading all go to market activities. A seasoned software executive, he has over 20 years of expertly selling, building and leading sales teams to massive increases in revenue for high growth companies focused on data and open source technologies. Prior to joining Tile DB, he has worked for large public to early stage startup companies, such as: CockroachLabs, Mesosphere, Hortonworks, VMware & Novell. When not working, Eric enjoys golfing, tennis, swimming, coaching youth sports and watching his favorite sports teams (Go Jets!!!). Today, he lives in Livingston, NJ with his wife & three children.',
    image: EricGoldstein,
  },
  {
    name: 'Chase Christensen',
    title: 'Staff Solutions Engineer',
    link: 'https://www.linkedin.com/in/chase-c-695463162/',
    bio: "Chase is people-prioritized, open source-harmonized, and impact driven machine learning sales engineer. Chase believes in helping teams get more out of their tools via a people-centric approach. Chase developed a passion for people-centric solutions early on in his career within the video game industry where he first hand saw the toil of manually testing. Since then, Chase has worked at organizations seeking to build solution to reduce arduous and risky human interactions with systems including Arrikto where he was part of team building a distribution for the open-source ML Toolkit Kubeflow . Chase's passion for setting up teams for success is also present in his course building for the Cloud Native Computing Foundation around ML Toolkits and MLOPs.",
    image: ChaseChristensen,
  },
  {
    name: 'Aneesa Valentine',
    title: 'Customer Success Engineer',
    link: 'https://www.linkedin.com/in/aneesav/',
    bio: 'Aneesa is a Scientist at heart. Her academic Research career spanned well over 5 years, contributing to high-impact peer-reviewed publications spanning Biophysics, Microbiology & Immunology and most recently, Genomics. After 2 years in a Bioinformatics PhD program, a budding interest in Data Science & AI prematurely drew her out. As a result, she transitioned to the commercial Data Science space where she productized ML models for Top 10 Cloud computing stakeholders and spearheaded Healthcare analytics endeavors impacting millions of users. Aneesa enjoys a challenge, both personally and professionally. She also deeply values continuous learning. In her spare time you’ll find her at the gym, a live comedy show, co-working at a coffee shop or at the occasional symphony.',
    image: AneesaValentine,
  },
  {
    name: 'John A. Carter',
    title: 'Account Executive',
    link: 'https://www.linkedin.com/in/johnacarter/',
    bio: 'John A. Carter is a 25 year veteran of data modernization initiatives with an emphasis on analytics, AI & ML enablement. The last 4 years have been focused in top tier pharmaceutical R&D. I’m passionate about selling software and accelerating our clients abilities to save human lives in the process. In my spare time I enjoy working out, golfing, tennis or pickleball with my wife and spoiling my two pit bulls! I am also a passionate chef and enjoy a nice wine while at it!',
    image: JohnACarter,
  },
  {
    name: 'Barry Connolly',
    title: 'Account Executive',
    link: 'https://www.linkedin.com/in/brconnolly/',
    bio: 'When I am not working for my customers supporting their technology and science, you will find me in the mountains, either hiking or skiing with my wife, and our two Chocolate Labrador Retrievers, Abbie and Gracie.',
    image: BarryConnolly,
  },
  {
    name: 'Ryan Roelke',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/ryan-roelke-968909288/',
    bio: "Ryan is a software engineer with a specialization in analytical query processing systems. Ryan received his BS and MS in computer science from Brown University and is a co-author of 'Pivot Tracing', one of the SOSP 2015 Best Paper Award recipients. Prior to joining TileDB he was an engineer and manager on the Vertica analytical database Execution Engine team. When Ryan has a free evening, he likes to spend it with tabletop board games or playing viola with a community orchestra.",
    image: RyanRoelke,
  },
  {
    name: 'Ryan Williams',
    title: 'Staff Software Engineer',
    link: 'https://www.linkedin.com/in/ryanwilliams0/',
    bio: "Ryan has 10 years' experience building distributed systems for processing genomic data, at Hammer Lab at Mount Sinai in New York, and as a committer on the ADAM and Zarr projects. He has also worked on geospatial software at Google and Foursquare. He lives in Jersey City and is active with transit and bike advocacy groups in NJ and NY.",
    image: RyanWilliams,
  },
  {
    name: 'Sean McGillen',
    title: 'Staff Solutions Engineer',
    link: 'https://www.linkedin.com/in/seanmcgillen/',
    bio: 'Sean is a Solutions Engineer on the Field Engineering team at TileDB. He has worked in sales, presales, and sales enablement at TIBCO, Dataiku, TetraScience, and Lightbend among others. As a member of the Life Sciences team at TileDB, Sean leverages previous experience at Merck, Pfizer, Amgen, Sisters Of Mercy Health Systems, and Children’s Hospital of Los Angeles to help our customers and prospects. He’s passionate about the application of AI and ML in solving problems and improving conditions for both individuals and businesses. He has four children and two grand-daughters. Sean and his wife, Lorraine, are avid sailboat racers. He is also a U.S. Sailing Certified Race Officer and officiates regattas throughout the United States.',
    image: SeanMcGillen,
  },
  {
    name: 'Spencer Seale',
    title: 'Solutions Architect',
    link: 'https://www.linkedin.com/in/spencerseale/',
    bio: 'Spencer Seale is a Solutions Architect at TileDB. He holds a B.S. in Biology from Seattle University and a M.S. in Bioinformatics and Genomics from the University of Oregon. His professional trajectory spans various domains within life sciences, encompassing roles in wet- lab experimentation, computational biology, and software engineering. Drawing from his diverse background, Spencer understands the complexities inherent in multi-disciplinary teams. He thrives on crafting methodologies that enhance data accessibility and democratize computational resources, thereby empowering teams to innovate collaboratively. In his free time, Spencer enjoys skiing, camping with his chocolate Labrador, Slice, and developing software to solve random challenges that cross his path.',
    image: SpencerSeale,
  },
  {
    name: 'Devika Garg',
    title: 'Director of Product Marketing',
    link: 'https://www.linkedin.com/in/devikagarg/',
    bio: 'Devika Garg leads product marketing for life sciences at TileDB. Prior to TileDB, she ran marketing engines at Pure Storage, Proteus Digital Health and Applied Materials. A scientist and a science journalist in her past life, she loves to geek out on the latest discoveries and inventions. She earned her PhD at the National University of Singapore, her MS in Science Communication at University of California Santa Cruz, and her B Tech at the Indian Institute of Technology Kanpur.',
    image: DevikaGarg,
  },
  {
    name: 'Dimitris Sideris',
    title: 'Senior Software Engineer II',
    link: '',
    bio: 'Dimitris has more than 13 years of software engineering experience and has been working with designing, implementing and optimizing backend systems. Has obtained his computer engineering degrees from Technical University of Crete and National Kapodistrian University of Athens with a focus to databases and distributed systems. Prior to TileDB has been working in crateDB building a distributed nosql database and a real time analytics/notification platform. Has has experience working with many IoT projects with timeseries data problems and in software defined networking. He loves books, music, exploring Greek nature and enjoys having quality time with friends and family.',
    image: DimitrisSideris,
  },
  {
    name: 'Bruce Martin',
    title: 'Principal Software Engineer',
    link: 'https://www.linkedin.com/in/brucemartin5/',
    bio: 'Bruce is an experienced engineer and product leader, with a background in distributed systems, large scale data processing and life science applications. Prior to TileDB, Bruce worked at the Chan Zuckerberg Foundation building tools in support of science.',
    image: BruceMartin,
  },
  {
    name: 'Jeremy Balian',
    title: 'Head of Business Development',
    link: 'https://www.linkedin.com/in/jeremybalian/',
    bio: "Jeremy leads partner strategy, engagements and activities at TileDB. A long-time software sales executive, Jeremy brings experience for solving customer problems, accomplishing business objectives and achieving operational success. He has served in Business and Technical positions at AI/ML, Application Platform, Public Cloud and SaaS Analytics companies. He's held roles in Sales through Support including Product Management, Consulting and Technical leadership. He studied Computer Science at the University of Virginia, studied French both in the US and abroad as well as pursued graduate coursework in Information Science and Computational Linguistics. When not working, he spends his time refereeing and coaching soccer, skiing, reading science fiction and figuring out what to do with his wife once their 17-year old triplet boys leave home!",
    image: JeremyBalian,
  },
  {
    name: 'George Vlastarakis',
    title: 'Sales Development Representative (SDR)',
    link: 'https://www.linkedin.com/in/georgevlastar/',
    bio: 'George is a Sales Development Representative at TileDB. Prior to joining TileDB, he led the business development at early stage startup Trigo (YC W23) where he focused on enterprise sales in the property management and real estate tech sectors. George received a B.S. in Economics from Carnegie Mellon University, and was born and raised in Athens, Greece. In his free time, he plays soccer and water polo, he paints, and enjoys spending time with friends.',
    image: GeorgeVlastarakis,
  },
  {
    name: 'John Moutafis',
    title: 'Senior Software Engineer',
    link: 'https://www.linkedin.com/in/john-moutafis-b6a131127/',
    bio: 'John holds an M.Sc degree in GIS from the National Technical University of Athens. He is a highly motivated software developer, with an affinity for new technologies and a deep interest in GIS, their real life application and implementation. Prior joining TileDB, he was working as a Senior Backend and Infrastructure Engineer for a highly skilled R&D team specialized in real language scraping/processing and information assisted decision making for the Shipping Sector. He has also worked as a GIS Software Engineer in large scale projects.',
    image: JohnMoutafis,
  },
  {
    name: 'Bharat Manmode',
    title: 'Director of Customer Support',
    link: 'https://www.linkedin.com/in/bharat-manmode-1550a51a/',
    bio: 'Bharat Manmode joins the TileDB team as head of customer support. Bharat holds a masters in computer science and has brought over 18 years of experience in customer service and leadership to the team with a passion for enhancing customer experiences and a commitment to operational excellence. Prior to joining TileDB, Bharat worked for large public to early stage startup companies, he ran customer support division for DataRobot and was in support leadership roles at Persistent System, MasterCard and Epsilon.  Bharat has a proven track record of implementing innovative support strategies, optimizing processes, and fostering a customer-centric culture. Known for their strategic vision and hands-on approach, he ensures that every customer interaction is handled with care, empathy, and efficiency.  Outside of work, Bharat enjoys biking, playing  cricket, badminton and coaching youth soccer, which help maintain a balanced and motivated perspective in his professional endeavors.',
    image: BharatManmode,
  },
  {
    name: 'Paolo Narvaez',
    title: 'VP of Life Sciences',
    link: 'https://www.linkedin.com/in/paolonarvaez/',
    bio: 'Paolo Narvaez brings over 20 years of experience in computer system architecture and in leading product and solution development. Prior to TileDB, Paolo was Sr. PE and Sr. Director at Intel Corporation, leading the development of enterprise reference designs. During his Intel tenure, he led the Health and Life Sciences engineering team, working on building and optimizing solutions for the life sciences market. Paolo also held architecture roles at Alcatel-Lucent, RMI (acquired by Broadcom), and Sycamore Networks. Paolo received his PhD, MEng, and SB degrees in EECS from the Massachusetts Institute of Technology.',
    image: PaoloNarvaez,
  },
  {
    name: 'Molly Clancy',
    title: 'Sales Development Representative (SDR)',
    link: 'www.linkedin.com/in/molly-clancy-1a9493265',
    bio: 'Molly is a Sales Development Representative at TileDB. Before joining TileDB, she earned a B.S. in Business Administration from the College of Charleston, gaining expertise in business management and financial accounting. Originally from Ridgefield, Connecticut, Molly enjoys playing tennis, reading, and spending time with friends in her free time.',
    image: MollyClancy,
  },
  {
    name: 'Christina Pucci',
    title: 'Sr. Business Development Representative',
    link: 'https://www.linkedin.com/in/cpucci',
    bio: 'Christina is a Senior Business Development Representative at TileDB, specializing in the biopharma market. Previously, she has worked at companies ranging in size from VC-backed start-ups to Fortune 500– addressing the life sciences market in a sales capacity. A scientist herself, she spent about a decade behind the bench at places like the Stowers Institute for Medical Research, and has numerous publications and grants under her belt, primarily focused on the area of cancer genetics research. Born and raised in Kansas City Missouri, she has also lived in Berlin where she studied Molecular Medicine at the Charité. Outside of work, some of her hobbies include music, art, dance, reading, and world travel. She also loves organic gardening, being in nature, and spending time with her family.',
    image: ChristinaPucci,
  },
  {
    name: 'Jamie Sherkness',
    title: 'Events & Experience Lead',
    link: 'https://www.linkedin.com/in/jamiezale/',
    bio: 'Jamie brings over 10 years of event management experience to her role as Events & Experience Lead at TileDB. After earning her B.S. in Hospitality Management from East Carolina University, she began her career in higher education before discovering her passion for the dynamic tech industry. Known for her innovative thinking and ability to elevate events to new heights, Jamie excels in creating memorable experiences. In her free time, she enjoys relaxing at the beach and visiting Disney World with her family.',
    image: JamieSherkness,
  },
  {
    name: 'Daniel Goya',
    title: 'Sales Development Representative (SDR)',
    link: 'www.linkedin.com/in/daniel-goya-6a529b175',
    bio: 'Daniel Goya is a Sales Development Representative at TileDB, where he applies his diverse expertise to drive business growth and build strong client relationships. Before joining TileDB, Daniel was the Senior Nutrition Consultant at Project LeanNation. Having studied internationally and attaining his Grand Diplôme from Le Cordon Bleu Paris, Daniel`s background is as dynamic as it is broad. His culinary experience includes working with Michelin-starred chefs and presenting to international dignitaries. While fluent in Spanish, French, and English, Daniel is also skilled in strategic partnerships, bringing a well-rounded approach to his role at TileDB.',
    image: DanielGoya,
  },
  {
    name: 'Thomas Souliotis',
    title: 'Staff Software Engineer',
    link: 'https://www.linkedin.com/in/thomas-souliotis-696389150',
    bio: "Thomas Souliotis is a graduate of ECE-NTUA, Greece, with an MSc in Computer Science from the University of Edinburgh. Over the past six years at Aisera, Thomas advanced from Software Engineer to Senior Principal Engineer, leading two teams—one focused on developing Aisera's workflow engine and another on advancing ASR technology for voice solutions. Thomas values strong, collaborative relationships with his colleagues and is passionate about tackling challenging projects and pushing the boundaries of technology.",
    image: ThomasSouliotis,
  },
  {
    name: 'Angela Brown',
    title: 'Director of Marketing Operations',
    link: 'https://www.linkedin.com/in/angela-brown-999b5a10',
    bio: 'Angela Brown is the Director of Marketing Operations. She brings decades of experience from roles in both small and large organizations with a keen focus on customer experience and operational excellence. Her speciality is in strategic alignment & helping organizations link strategic goals through practical applications for improved execution. Before TileDB, Angela led an operations team at a non-profit organization focused on serving employers in NC & SC with all HR and people resource needs. Most recently, her focus has been in the change enablement space assisting internal leaders and employees with improving their skills, systems and processes to affect organizational outcomes. She lives in North Carolina with her husband and furry "child". Angela is a wellness enthusiast and foodie; she loves to be outside and enjoys sharing a great meal with friends & family.',
    image: AngelaBrown,
  },
  {
    name: 'Mikael Austin',
    title: 'Account Executive',
    link: 'https://www.linkedin.com/in/mikael-austin-87400554/',
    bio: 'Mikael is an account executive at TileDB. Prior to joining TileDB, she worked at other fast growing startups from an early stage, such as Cockroach Labs and Qwak, helping build the customer base and team culture. Mikael received her B.S. from Cornell University. In her free time you can find her traveling, golfing, dancing, hiking, writing, and hanging with friends/family. Mikael is a proud Gryffindor, loyal fan of the NY Rangers and Beyonce, and lives in NYC.',
    image: MikaelAustin,
  },
];

interface PopupDataProps {
  image: string;
  name: string;
  title: string;
  bio: string;
}

const Team: React.FC = () => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<PopupDataProps>({
    image: '',
    name: '',
    title: '',
    bio: '',
  });

  const initPopup = (data: PopupDataProps) => {
    setPopupData(data);
    setPopupOpen(!popupOpen);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useKeyDown(
    'Escape',
    () => {
      setPopupOpen(false);
    },
    []
  );

  const [shuffledMembers, setShuffledMembers] = useState<Member[]>([]);

  useEffect(() => {
    const nonShuffled = [...members];
    const shuffled = [];
    for (let i = 0; i < members.length; i++) {
      const randomIndex = Math.floor(Math.random() * nonShuffled.length);
      const m = nonShuffled.splice(randomIndex, 1);
      shuffled.push(m[0]);
    }
    setShuffledMembers(shuffled);
  }, []);

  return (
    <>
      <Section name="team">
        <h2 className="section__title">Team</h2>
        <div className="section__container">
          {shuffledMembers.map((member: Member): ReactElement => {
            return <MemberCard key={member.name} {...member} onClick={initPopup} />;
          })}
        </div>
      </Section>
      <Popup name="bio" isOpen={popupOpen} onCloseClicked={closePopup}>
        <div className="image-container">
          <img className="image" src={popupData.image} alt={popupData.name} />
        </div>
        <div className="name">{popupData.name}</div>
        <div className="title">{popupData.title}</div>
        <div className="bio">{popupData.bio}</div>
      </Popup>
    </>
  );
};

export default Team;
